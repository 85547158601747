import React, { useEffect, useState, useRef } from 'react';
import CopyToClip from '../../resources/images/copytoclipboard.svg';
import Share3 from '../../resources/images/share3.svg';
import { hitDeeplinkExecutedV2 } from '../../utils/functions';

import { getQuery, makeApiCal } from '../../utils/functions';
import NotEmpty from './NotEmpty';
import Toast from '../UI/Toast';

import Modal from '../UI/Modal';
import WarningCard from '../UI/WarningCard';
import BottomUpCardSuccess from '../UI/BottomUpCardSuccess';
import BottomUpShareModal from '../UI/BottomUpShareModal';
import LandingTemplate from '../UI/LandingTemplate';
import Header from '../UI/Header';
import Error from '../UI/Error';

import { Trans, useTranslation } from "react-i18next"

function TestimonialResponse({ name }) {
  const [apiData, setapiData] = useState({});
  const [initialValuesPresent,setInitialValuesPresent]=useState(false);
  const initialMountRef=useRef(true);
  const [renderData, setRenderData] = useState([]);
  const [checkedArray, setCheckedArray] = useState([]);
  const [unCheckedArray, setUnCheckedArray] = useState([]);
  const [isLoader, setLoader] = useState(true);
  const [offset, setOffset] = useState(0);
  const [searchString, setSearchString] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [isModalDialog, setIsModalDialog] = useState(false);
  const [modalClassName, setModalClassName] = useState(false);
  const [showShareModal, setShareModal] = useState(false);
  const [copyText, setCopyText] = useState('');
  const [isError, setError] = useState(false);
  const [isGenericShare,setIsGenericShare] = useState(false);
  const initialCheckedArray = useRef([]);
  const initialUnCheckedArray = useRef([]);
  const textareaRef = useRef(null);
  const limit = 10;

  useEffect(() => {
    let genericShare = Boolean(eval(getQuery()['isGenericShare'] || false));
    setIsGenericShare(genericShare)
  },[])

  const { t } = useTranslation();

  const apiCall = () => {
    setLoader(true);
    makeApiCal({
      method: 'GET',
      route: `v2/course/studentTestimonial?limit=${limit}&offset=${offset}&search=${searchString}`,
      storeData: (data) => {
        setLoader(false);
        setError(false);
        setOffset(offset + limit);

        setCopyText(data?.studentTestimonial?.copyUrl);
        //offset>0 no need to change this array again
        if (offset === 0) {
          data?.studentTestimonial?.studentTestimonial?.userPublishedStudentTestimonial?.map(
            (e) => {
              initialCheckedArray.current.push(e.id);
            }
          );
        }
        initialCheckedArray.current = [...new Set(initialCheckedArray.current)];
        data?.studentTestimonial?.studentTestimonial?.userUnpublishedStudentTestimonial?.map(
          (e) => {
            initialUnCheckedArray.current = [
              ...initialUnCheckedArray.current,
              e.id,
            ];
          }
        );
        initialUnCheckedArray.current = [
          ...new Set(initialUnCheckedArray.current),
        ];

        let t = [...initialCheckedArray.current, ...checkedArray];
        setCheckedArray([...new Set(t)]);
        // setCheckedArray([...new Set(initialCheckedArray.current,checkedArray)]);
        setUnCheckedArray([...initialUnCheckedArray.current]);
        if(initialMountRef.current){
          if(data?.studentTestimonial?.studentTestimonial
            ?.userUnpublishedStudentTestimonial?.length>0 || data?.studentTestimonial?.studentTestimonial
            ?.userUnpublishedStudentTestimonial?.length>0){
              initialMountRef.current=false;
              setInitialValuesPresent(true);
              setRenderData(
                offset > 0
                  ? [
                      ...renderData,
                      ...data?.studentTestimonial?.studentTestimonial
                        ?.userUnpublishedStudentTestimonial,
                    ]
                  : [
                      ...data?.studentTestimonial?.studentTestimonial
                        ?.userPublishedStudentTestimonial,
                      ...data?.studentTestimonial?.studentTestimonial
                        ?.userUnpublishedStudentTestimonial,
                    ]
              );
            }
        }
        setapiData({ ...data });
      },
      catchError: (err) => {
        console.log(err);
        setLoader(false);
        setError(true);
      },
    });
  };
  useEffect(() => {
    // apiCall();

    if (apiData?.studentTestimonial?.studentTestimonial?.count === 0) {
    }
  }, [apiData]);
  useEffect(() => {
    // if (searchString) {
    setOffset(0);

    // }
  }, [searchString]);
  useEffect(() => {
    if (offset === 0) {
      apiCall();
    }
  }, [offset]);

  const handleCopyToClipBoard = () => {
    const el = textareaRef.current;
    el.select();
    document.execCommand('copy');

    setToastMessage(t('tutor.toast.linkCopied','Link copied successfully'));
    setShowToast(true);
  };

  const handleWhatsapp = () => {
    const text =`${t('tutor.whatsappText','Hello Student! Please fill the form to share your Learning Experience at')  }${copyText}`;

    if(isGenericShare) {
      hitDeeplinkExecutedV2(
        JSON.stringify({
          screen: "UTIL_SHARE",
          paramOne: text,
        }),
        JSON.stringify({
          screen: "UTIL_SHARE",
          paramOne: text,
        })
      );
    } else {
      const whatsAppText = encodeURI(text);
      window.open(`https://wa.me/?text=${whatsAppText}`);
    }

  };
  let templateData = {
    heading: `${t('tutor.templateData.heading','Introducing Student Testimonials')}`,
    subHeading: `${t('tutor.templateData.subheading','A great deal of research finds that word of mouth is more effective than other types of marketing. Let your students know why your coaching institute is the best. Simply collect their response and choose which to publish.')}`,
    mainButton: {
      text: t('tutor.templateData.mainbtnText','Share Link'),
      image: Share3,
      bgColor: isGenericShare ? "#009ae0" : '#33CC66',
      handleClick: handleWhatsapp,
    },
    auxButton: {
      text: '',
      image: CopyToClip,
      bgColor: '1px solid #009AE0',
      handleClick: handleCopyToClipBoard,
    },
  };

  const handleChange = (e) => {
    setSearchString(e.target.value);
  };
  const handleCheckBoxClick = (e) => {
    const { checked, name } = e.target;

    let tempCheckedArray = [];
    let tempUnCheckedArray = [];
    if (checked) {
      tempCheckedArray = [...checkedArray, parseInt(name, 10)];
      tempUnCheckedArray = unCheckedArray.filter((e) => e !== +name);
    } else {
      tempUnCheckedArray = [...unCheckedArray, parseInt(name, 10)];
      tempCheckedArray = checkedArray.filter((e) => e !== +name);
    }
    setCheckedArray([...tempCheckedArray]);
    setUnCheckedArray([...tempUnCheckedArray]);
  };
  const canPublishOrUnPublish = () => {
    const equalsIgnoreOrder = (a, b) => {
      if (a.length !== b.length) return false;
      const uniqueValues = new Set([...a, ...b]);
      for (const v of uniqueValues) {
        const aCount = a.filter((e) => e === v).length;
        const bCount = b.filter((e) => e === v).length;
        if (aCount !== bCount) return false;
      }
      return true;
    };

    if (equalsIgnoreOrder(initialCheckedArray.current, checkedArray)) {
      return true;
    }
    return false;
  };
  const unpublishCards = () => {
    postApiCall(false);
  };
  const postApiCall = (isPublish) => {
    makeApiCal({
      method: 'POST',
      route: `v2/course/studentTestimonial/publish`,
      body: {
        testimonialList: isPublish ? checkedArray : [],
      },
      storeData: (res) => {
        console.log(res);
        initialCheckedArray.current = [];
        initialUnCheckedArray.current = [];
        setCheckedArray([]);
        setUnCheckedArray([]);
        setOffset(0);
        if (isPublish) {
          setIsModalDialog(false);
          setShowModal(true);
          setModalClassName('');
          setShareModal(false);
        }
      },
      catchError: (err) => {
        console.log(err);
      },
    });
  };
  const handleMainButtonClick = () => {
    if (canPublishOrUnPublish()) {
      setShowModal(true);
      setIsModalDialog(true);
      setModalClassName('ModalDialog');
    } else {
      if (checkedArray.length <= 10) {
        //can pulish
        // setIsModalDialog(false);
        // setShowModal(true);
        // setModalClassName('');
        // setShareModal(false);
        postApiCall(true);
      } else {
        //throw toast
        setToastMessage(t('tutor.toastMsg.upto10char','You can select only upto 10 testimonials'));
        setShowToast(true);
      }
    }
  };
  const handleShowToast = () => {
    setShowToast(false);
  };
  const handleAuxButtonClick = () => {
    setShareModal(true);
    setShowModal(true);
    setIsModalDialog(false);
    setModalClassName('');
  };
  const fetchMoreData = () => {
    apiCall();
  };
  const isMoreData = () => {
    if (
      apiData?.studentTestimonial?.studentTestimonial?.count ===
      renderData?.length
    ) {
      return false;
    }
    return true;
  };

  // let renderList=[...]
  const headerBack = () => {
    //android
    window?.mobile?.setTestState(``);
    // ios
    window?.webkit?.messageHandlers?.stateEvents?.postMessage('QUIT');
  };
  const setTitle = () => {
    if (checkedArray?.length > 0) {
      return `${checkedArray?.length} ${t('tutor.title.selected','Selected')}`;
    }
    return t('tutor.studentTestimonial','Student Testimonials');
  };

  return (
    <div>
      <Header handleClick={headerBack} title={setTitle()} />
      {isError ? (
        <Error />
      ) : (
        <>
          {renderData?.length > 0 ||
          initialCheckedArray?.length > 0 ||
          initialValuesPresent||
          checkedArray?.length > 0 ? (
            <NotEmpty
              renderData={renderData}
              checkedArray={checkedArray}
              unCheckedArray={unCheckedArray}
              setCheckedArray={setCheckedArray}
              setUnCheckedArray={setUnCheckedArray}
              search={searchString}
              handleChange={handleChange}
              handleCheckBoxClick={handleCheckBoxClick}
              canPublishOrUnPublish={canPublishOrUnPublish}
              showModal={showModal}
              setShowModal={setShowModal}
              handleMainButtonClick={handleMainButtonClick}
              handleAuxButtonClick={handleAuxButtonClick}
              unpublishCards={unpublishCards}
              initialCheckedArray={initialCheckedArray.current}
              fetchMoreData={fetchMoreData}
              isMoreData={isMoreData}
              isLoader={isLoader}
            />
          ) : (
            <>
              {' '}
              <LandingTemplate data={templateData} id="LPNoData" />
            </>
          )}
        </>
      )}

      {showToast && (
        <Toast
          showToast={showToast}
          message={toastMessage}
          handleShowToast={handleShowToast}
        />
      )}
      <textarea
        ref={textareaRef}
        value={copyText}
        readOnly
        // disabled
        style={{ position: 'fixed', top: '-100vh' }}
        onFocus={(e) => {
          e.preventDefault();
        }}
        onInput={() => {}}
      />
      {showModal && (
        <Modal propClass={modalClassName} isDialog={isModalDialog}>
          {isModalDialog ? (
            <WarningCard
              unpublishCards={unpublishCards}
              setShowModal={setShowModal}
            />
          ) : showShareModal ? (
            <BottomUpShareModal
              data={templateData}
              landingTemplateId={'LTmodaltype'}
              setShowModal={setShowModal}
            />
          ) : (
            <BottomUpCardSuccess setShowModal={setShowModal} />
          )}
        </Modal>
      )}
    </div>
  );
}

export default TestimonialResponse;
