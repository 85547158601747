import React from 'react';
import Back from '../../resources/images/WhiteArrow.svg';
import { directDeepLink } from '../../utils/functions';

function Header(props) {
  const { title } = props;

  const handleClick = () => {
    directDeepLink(``);
  };

  return (
    <div className="Header">
      {/* <img className="Header__Back" src={Back} alt="<" onClick={handleClick} /> */}
      <span className="Header__Title">{title}</span>
    </div>
  );
}

export default Header;
