import axios from 'axios';
import axiosWL from './axios-wl.js';
import API from './AppConfig';
import { toast } from 'react-toastify';
import { mimeTypes } from './s3UploadMimeTypes';
import { getQuery } from './functions.js';
import { POST } from './constants.js';

export default async function (files) {
  let token = getQuery()['token'] || localStorage.getItem('TestimonialToken');
  // let token =
  //   'eyJhbGciOiJIUzM4NCIsInR5cCI6IkpXVCJ9.eyJpZCI6Mjg5OTk0LCJvcmdJZCI6MTcwLCJuYW1lIjoiR3VuamFuIEd1cHRhIiwiZW1haWwiOiJndW5qYW5AY2xhc3NwbHVzYXBwcC5jb20iLCJtb2JpbGUiOiI5MTk3MTExNTMwNzkiLCJ0eXBlIjozLCJpYXQiOjE2MzAwNTE2OTYsImV4cCI6MTYzMDY1NjQ5Nn0.weJjpcNMEftyS8lJtGEkmx2pA1bhEyIgo6CB00HMGmMTCHTpK4xabh89JRCEJp1W';
  // toast.success(token);

  let publicId = makeId(20);
  const headers = {
    'content-type': 'application/json',
    'x-access-token': token,
  };
  let count = files.length;
  let fileExtensions = files.map((file, index) => {
    // toast.success(`GETTING FILE EXTENSION FOR - ${file.name}`);
    return {
      name: getFileExtension(file.name),
      id: index + 1,
    };
  });
  let url = `v2/users/getS3SignedUrl`;
  // toast.success('AXIOS');
  return axios({
    url,
    method: POST,
    data: { exts: fileExtensions },
    baseURL: API.BASE_URL,
    undefined,
    headers: headers,
  })
    .then((response) => {
      // toast.success('STEP 1');
      const signedUploadUrls = response.data.data;
      const downloadUrls = response.data.data.map((ob) => {
        return {
          url: ob.publicUrl,
          fileName: '',
          public_id: '',
          format: '',
        };
      });
      let successUrls = [];
      let fileData = files.map((file, index) => {
        downloadUrls[index].fileName = file.name;
        downloadUrls[index].format = getFileExtension(file.name).toLowerCase();
        downloadUrls[index].public_id = publicId;
        return {
          ...file,
          uploadSignedUrl: signedUploadUrls[index].uploadSignedUrl,
        };
      });
      let uploadedCount = 0;
      let errorCount = 0;
      let rejectFileIds = [];
      return new Promise((resolve, reject) => {
        fileData.forEach((file, i) => {
          let options = {
            headers: {
              'content-type': getMimeType(files[i]),
              'accept-language':
                getQuery()['defaultLanguage']?.substring(0, 2)?.toLowerCase() ||
                'en',
            },
          };
          axiosWL
            .put(file.uploadSignedUrl, files[i], options)
            .then((res) => {
              // toast.success('STEP 2');
              uploadedCount = uploadedCount + 1;
              successUrls.push(downloadUrls[i]);
              if (
                successUrls.length + rejectFileIds.length ===
                fileData.length
              ) {
                resolve(successUrls);
              }
            })
            .catch((e) => {
              errorCount = errorCount + 1;
              rejectFileIds.push(i);
              toast.warn(e);
              if (
                successUrls.length + rejectFileIds.length ===
                fileData.length
              ) {
                resolve(successUrls);
              }
            });
        });
      });
    })
    .catch((err) => {
      console.error(err);
      toast.error(err);
    });
}

function getFileExtension(filename) {
  const result = filename.slice(((filename.lastIndexOf('.') - 1) >>> 0) + 2);
  // toast.success(`EXTENSION - ${result}`);
  return result;
}
const makeId = (length) => {
  // toast.success('MAKING ID');
  var result = '';
  var characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  // toast.success(`ID - ${result}`);
  return result;
};
function getMimeType(file) {
  let ext = getFileExtension(file.name);
  // toast.success(`MIME - ${mimeTypes[ext]}`);
  return mimeTypes[ext];
}
