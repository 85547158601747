import React from 'react';

import Template from '../../resources/images/LandingTemplate.svg';
function LandingTemplate(props) {
  const { data } = props;

  return (
    <div className="LandingTemplate" id={props.id}>
      <img className="LandingTemplate__Image" src={Template} alt="te" />
      <div className="LandingTemplate__Text">
        <div className="LandingTemplate__Text--Heading">{data?.heading}</div>
        <div className="LandingTemplate__Text--SubHeading">
          {data?.subHeading}
        </div>
      </div>
      <div className="LandingTemplate__Buttons">
        <div
          className="LandingTemplate__Buttons--MainButton"
          onClick={data?.mainButton?.handleClick}
          style={{ backgroundColor: data?.mainButton?.bgColor }}
        >
          {data?.mainButton?.image ? (
            <img src={data?.mainButton?.image} alt="buttonimage" />
          ) : (
            ''
          )}
          <span> {data?.mainButton?.text}</span>
        </div>
        {data?.auxButton && (
          <div
            className="LandingTemplate__Buttons--AuxButton"
            onClick={data?.auxButton?.handleClick}
            style={{
              borderColor: data?.auxButton?.bgColor,
              border: data?.auxButton?.bgColor,
            }}
          >
            <img src={data?.auxButton?.image} alt="auxButton" />
          </div>
        )}
      </div>
    </div>
  );
}

export default LandingTemplate;

// const DATA = {
//     heading: "",
//     subHeading: "",
//     mainButton: {
//         text: "",
//         image: "",
//         bgColor: "",
//         handleClick: ""
//     },
//     auxButton: {
//         image: "",
//         bgColor: "",
//         handleClick: ""

//     }
// }
