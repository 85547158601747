import React from 'react';
import Header from '../UI/Header';
import LandingTemplate from '../UI/LandingTemplate';
import Loader from '../UI/Loader';
import TestimonialCard from '../UI/TestimonialCard';
import Success from './Form/Success';

import { Trans, useTranslation } from "react-i18next"

function StudentHome(props) {
  const { testimonial, entries, templateData, showSuccesModal, loading } =
    props;

    const { t } = useTranslation();


  return (
    <div className="StudentHome">
      <Header title={t("studentHome.testimonialHeader","Students Testimonial")} />
      {loading ? (
        <Loader />
      ) : testimonial ? (
        <div className="StudentHome__Testimonial">
          <div className="StudentHome__Testimonial--ThankYou">
            {t("studentHome.dear1","Dear")} {entries?.name}{t("studentHome.forKor",",")}{t("studentHome.thankyou1","thank you")}!
          </div>
          <TestimonialCard data={entries} />
        </div>
      ) : (
        <LandingTemplate data={templateData} />
      )}
      {showSuccesModal && <Success />}
    </div>
  );
}

export default StudentHome;
