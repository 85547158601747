import React from 'react';
import './BottomButtons.scss';
import ShareBlue from '../../resources/images/shareblue.svg';
import { useTranslation } from 'react-i18next';
const BottomButtons = ({ changed,handleMainButtonClick,primaryButtonEnabled,handleAuxButtonClick }) => {
 const {t} = useTranslation()
  return (
    <div className='Bottom__Button__Wrapper'>
      <button className={changed ? 'Main__Button' : 'Main__Button unpublish'} onClick={()=>handleMainButtonClick()} disabled={primaryButtonEnabled}>
        {changed ? t("form.coursename.publishTestimonial",'Publish Testimonials') : t("form.coursename.turnOffTestimonial",'Turn Off Testimonials')}
      </button>
      <button className={'Aux__Button'} onClick={()=>handleAuxButtonClick()}>
        <img src={ShareBlue} alt='sh' />
      </button>
    </div>
  );
};

export default BottomButtons;
