import React, { useEffect } from 'react';
import './toast.scss';

const Toast = ({ message, showToast, handleShowToast }) => {
  useEffect(() => {
    if (showToast) {
      setTimeout(() => {
        handleShowToast();
      }, 2000);
    }
  }, [showToast]);

  return <div className="Toast__Body">{message}</div>;
};

export default Toast;
