import React, { useState } from 'react';
import CardContainer from './CardContainer/CardContainer';
import TutorCard from '../UI/TutorCard';
import SearchBox from '../UI/SearchBox';
import BottomButtons from '../UI/BottomButtons';
import { useTranslation } from 'react-i18next';

const NotEmpty = (props) => {
  //   const handleBlur = (e) => {};
  //   const handleFocus = (e) => {};
  const {t} = useTranslation()
  return (
    <div className="NotEmpty">
      <SearchBox
        // handleBlur={handleBlur}
        handleChange={(e) => props.handleChange(e, 'SEARCH_BATCHES')}
        name={'search'}
        value={props.search}
        placeHolder={t("form.coursename.searchPlaceholder",'Search')}
        // handleFocus={handleFocus}
      />

      <CardContainer
        data={props.renderData}
        checkedArray={props.checkedArray}
        unCheckedArray={props.unCheckedArray}
        setCheckedArray={props.setCheckedArray}
        setUnCheckedArray={props.setUnCheckedArray}
        handleCheckBoxClick={props.handleCheckBoxClick}
        fetchMoreData={props.fetchMoreData}
        isMoreData={props.isMoreData}
        isLoader={props.isLoader}
      />
      <BottomButtons
        changed={
          props.initialCheckedArray.length > 0 || props.checkedArray.length > 0
            ? !props.canPublishOrUnPublish()
            : true
        }
        handleMainButtonClick={props.handleMainButtonClick}
        handleAuxButtonClick={props.handleAuxButtonClick}
        primaryButtonEnabled={props.checkedArray.length > 0 ? false : true}
      />
    </div>
  );
};

export default NotEmpty;
