import React, { useState, useEffect } from 'react';
import { formInputs } from '../../../utils/constants';

import { Trans, useTranslation } from "react-i18next"

function Testimonial(props) {
  const { value, setValue, changePage, skip } = props;
  const { t } = useTranslation();
  const [disable, updateDisable] = useState(true);
  const [verifed, Verify] = useState(false);

  let maxLength = 140;

  useEffect(() => {
    if (value && value.length > 3) {
      updateDisable(false);
    } else {
      updateDisable(true);
    }
  }, [value]);

  const validateEntry = () => {
    if (value && value.length > 3) {
      Verify(false);
    } else {
      Verify(true);
    }
  };

  return (
    <div className="Form__Entry">
      <div className="Form__Entry--Label">{t("form.testimonial.writeYourTestimonial1","Write your testimonial")}</div>
      <div className="Form__Entry__Body">
        <div className="Form__Entry__Body__InputArea">
          <textarea
            className="Form__Entry__Body__InputArea--Input"
            type="text"
            placeholder={t("form.coursename.enterMessageHere","Enter your message here")}
            maxLength={maxLength}
            value={value}
            onChange={(e) =>
              setValue(
                e.target.value.replace(/\s\s+/g, ' ').slice(0, maxLength),
                formInputs.TESTIMONIALS
              )
            }
            onBlur={validateEntry}
          />
          <span>{value?.length ? maxLength - value.length : maxLength}</span>
        </div>

        {verifed && (
          <div className="Form__Entry__Body--Info">
            *{t("form.testimonial.enterYourMessage1","Please enter your message")}
          </div>
        )}
      </div>
      <button
        className="Form__Entry--Button"
        disabled={disable}
        onClick={() => changePage(1)}
      >
        {t("form.coursename.submit1","SUBMIT")}
      </button>
    </div>
  );
}

export default Testimonial;
