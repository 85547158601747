import React, { useState } from 'react';

import ProgressBar from '../../UI/ProgressBar';
import Name from './Name';
import Image from './Image';
import CourseName from './CourseName';
import Achievement from './Achievement';
import Testimonial from './Testimonial';

import FinalTestimonial from '../FinalTestimonial';

import Back from '../../../resources/images/WhiteArrow.svg';


function Form(props) {
  const { entries, setEntries, changePage, pageIndex, stepLoading } = props;

  const [image, setImage] = useState();

  const handleSkip = (type) => {
    setEntries(null, type);
    changePage(1);
  };

  const currentPage = (index) => {
    switch (index) {
      case 1:
        return (
          <Name
            stepLoading={stepLoading}
            value={entries.name}
            setValue={setEntries}
            changePage={changePage}
            skip={handleSkip}
          />
        );
      case 2:
        return (
          <Image
            stepLoading={stepLoading}
            value={entries.imageUrl}
            setValue={setEntries}
            changePage={changePage}
            skip={handleSkip}
            setImage={setImage}
          />
        );
      case 3:
        return (
          <CourseName
            stepLoading={stepLoading}
            value={entries.courseName}
            setValue={setEntries}
            changePage={changePage}
            skip={handleSkip}
          />
        );
      case 4:
        return (
          <Achievement
            stepLoading={stepLoading}
            value={entries.achievements}
            setValue={setEntries}
            changePage={changePage}
            skip={handleSkip}
          />
        );
      case 5:
        return (
          <Testimonial
            stepLoading={stepLoading}
            value={entries.testimonial}
            setValue={setEntries}
            changePage={changePage}
            skip={handleSkip}
          />
        );

      case 6:
        return (
          <FinalTestimonial
            stepLoading={stepLoading}
            value={entries}
            image={image}
            setValue={setEntries}
            changePage={changePage}
          />
        );
    }
  };
  return (
    <div className="Form">
      <ProgressBar
        barColor="#78D176"
        fillPercent={((pageIndex - 1) / 4) * 100}
      />
      <img
        className="Form--Back"
        src={Back}
        alt="<="
        onClick={() => changePage(-1)}
      />
      {currentPage(pageIndex)}
    </div>
  );
}

export default Form;
