import React from 'react';
import './CustomModal.scss';
function Modal(props) {
  const { children, propClass,isDialog } = props;
  return (
    <div className="ModalWrapper">
      <div className={isDialog?propClass:`Modal ${propClass}`}>{children}</div>
    </div>
  );
}

export default Modal;
