import { directDeepLink } from "../../utils/functions";
function BannerCarouselCard({
  bgImage,
  cta
}) {

  return (
    <div>
      <img 
        className="BannerCard"
        src={bgImage} 
        onClick={() => {
          directDeepLink(cta?.deeplink, 'SOURCE_HOME')();
        }}
        alt=""
      />
    </div>
  );
}

export default BannerCarouselCard;
