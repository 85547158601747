import React, { useEffect, useState } from "react";

import QuotesYellow from "../../resources/images/QuotesYellow.png";
import QuotesBlue from "../../resources/images/QuotesBlue.png";
import QuotesRed from "../../resources/images/QuotesRed.png";
import QuotesGreen from "../../resources/images/QuotesGreen.png";

function TestimonialCard({
  name,
  testimonial,
  courseName,
  imageUrl,
  achievements,
}) {
  const [background, setBackground] = useState();
  const colorSet = [
    {
      image:
        "https://cdn-cp-assets-public.classplus.co/cams/store/testimonial_background/Testimonial_1.svg",
      color: "#FFBD33",
      quotes: QuotesYellow,
    },
    {
      image:
        "https://cdn-cp-assets-public.classplus.co/cams/store/testimonial_background/Testimonial.svg",
      color: "#9CD3EE",
      quotes: QuotesBlue,
    },
    {
      image:
        "https://cdn-cloudn.classplus.co/web/contentStore/UpcomingClasses/TestimonialCardGreen.svg",
      color: "#A4DC88",
      quotes: QuotesGreen,
    },
    {
      image:
        "https://cdn-cloudn.classplus.co/web/contentStore/UpcomingClasses/Testimonial+Card.svg",
      color: "#FFA6A6",
      quotes: QuotesRed,
    },
  ];

  useEffect(() => {
    setBackground(colorSet[Math.floor(Math.random() * 4)]);
  }, []);

  return (
    <div
      className="PublishedCard"
      style={{ backgroundImage: `url(${background?.image})` }}
    >
      <div className="PublishedCard__Testimonial">
        <img src={background?.quotes} alt="" width="32px" />
        <div className="PublishedCard__Testimonial--Text">{testimonial}</div>
      </div>
      <div className="PublishedCard__Details">
        <div
          className="PublishedCard__Details__Image"
          style={{ border: `1px solid ${background?.color}` }}
        >
          {imageUrl ? (
            <img src={imageUrl} alt="Profile" />
          ) : (
            <div
              className="ProfilePlaceHolder"
              style={{ color: background?.color }}
            >
              {name[0]}
            </div>
          )}
        </div>
        <div className="PublishedCard__Details__Profile">
          <div className="PublishedCard__Details__Profile--Name">{name}</div>
          <div className="PublishedCard__Details__Profile--CourseName">
            {courseName}
          </div>
          {achievements ? (
            <div className="PublishedCard__Details__Profile--Achievements">
              {achievements}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

export default TestimonialCard;
