import React, { useState, useEffect } from 'react';
import { formInputs } from '../../../utils/constants';

import { Trans, useTranslation } from "react-i18next"

function Name(props) {
  const { value, setValue, changePage, stepLoading } = props;
  const { t } = useTranslation();
  const [disable, updateDisable] = useState(true);
  const [verifed, Verify] = useState(false);

  useEffect(() => {
    if (value && value.length > 3) {
      updateDisable(false);
    } else {
      updateDisable(true);
    }
  }, [value]);

  const validateEntry = () => {
    if (value && value.length > 3) {
      Verify(false);
    } else {
      Verify(true);
    }
  };

  return (
    <div className="Form__Entry">
      <div className="Form__Entry--Label">{t("form.name.enterName1","Please enter your name")}</div>
      <div className="Form__Entry__Body">
        <div className="Form__Entry__Body__InputArea">
          <input
            className="Form__Entry__Body__InputArea--Input"
            type="text"
            placeholder={t("form.coursename.enterYourName","Enter your name")}
            value={value}
            onChange={(e) => {
              setValue(e.target.value?.replace(/\s\s+/g, ' '), formInputs.NAME);
            }}
            onBlur={validateEntry}
          />
        </div>

        {verifed && (
          <div className="Form__Entry__Body--Info">*{t("form.name.enterNameError1","Please enter your name")}</div>
        )}
      </div>
      <button
        className="Form__Entry--Button"
        disabled={disable || stepLoading}
        onClick={() => changePage(1)}
      >
        {stepLoading ? t('form.name.loading','Loading...') : t('form.name.next','Next')}
      </button>
    </div>
  );
}

export default Name;
