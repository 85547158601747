import React, { useState, useEffect } from 'react';
import './student.scss';

import { formInputs, GET, POST } from '../../utils/constants';
import { getQuery, makeApiCal, parseToken } from '../../utils/functions';

import Form from './Form';
import StudentHome from './StudentHome';
import Error from '../UI/Error';


import { Trans, useTranslation } from "react-i18next"

function TestimonialForm(props) {


  const { t } = useTranslation();



  const [pageIndex, updatePageIndex] = useState(0);
  const [loading, updateLoading] = useState(true);
  const [stepLoading, updateStepLoading] = useState(false);

  const [getApiData, updateGetApiData] = useState();
  const [postApiRes, setPostApiRes] = useState();
  const [err, catchError] = useState();
  const [testimonialId, setTestimonialId] = useState('create');

  const [name, setName] = useState();
  const [courseName, setCourseName] = useState();
  const [imageUrl, setImageUrl] = useState();
  const [achievements, setAchievements] = useState();
  const [testimonial, setTestimonial] = useState();
  const [cardUrl, setCardUrl] = useState();

  const [showSuccesModal, setShowUpdateModal] = useState(false);

  useEffect(() => {
    let action = {
      method: GET,
      route: `v2/course/studentTestimonial`,
      storeData: updateGetApiData,
      catchError: catchError,
    };

    makeApiCal(action);
  }, []);

  useEffect(() => {
    const token =
      getQuery()['token'] || localStorage.getItem('TestimonialToken');

    const testimonial = getApiData?.studentTestimonial?.studentTestimonial[0];

    testimonial?.id && setTestimonialId(testimonial?.id);

    if (testimonial?.testimonial?.length) {
      setName(testimonial?.name);
      setCourseName(testimonial?.courseName);
      setImageUrl(testimonial?.imageUrl);
      setAchievements(testimonial?.achievements);
      setTestimonial(testimonial?.testimonial);
    } else if (token) {
      let tokenData = parseToken(token);
      setName(tokenData?.name);
    }

    updateLoading(false);
  }, [getApiData, err]);

  useEffect(() => {
    if (showSuccesModal) {
      updatePageIndex(0);

      setTimeout(() => {
        setShowUpdateModal(false);
      }, 4000);
    }
  }, [showSuccesModal]);

  useEffect(() => {
    if (postApiRes) {
      setTestimonialId(postApiRes?.testimonialId);

      if (pageIndex === 1) {
        updatePageIndex(2);
      } else if (pageIndex === 5) {
        setShowUpdateModal(true);
      }
    } else if (err) {
      console.log(err);
    }
  }, [postApiRes, err]);

  const changePage = (int) => {
    updateStepLoading(true);

    const action = {
      method: POST,
      route: `v2/course/studentTestimonial/${testimonialId}`,
      body: {
        name,
        courseName,
        imageUrl,
        achievements,
        testimonial,
      },
      storeData: (e) => {
        setPostApiRes(e);
        updateStepLoading(false);
      },
      catchError: catchError,
    };

    if ((pageIndex === 1 || pageIndex === 5) && int > 0) {
      makeApiCal(action);
    } else {
      updateStepLoading(false);
      updatePageIndex(pageIndex + int);
    }

    // updatePageIndex(pageIndex + int);
  };

  useEffect(() => {
    console.log(cardUrl);
  }, [cardUrl]);

  const setterFunction = (value, type) => {
    const {
      NAME,
      COURSE_NAME,
      IMAGE_URL,
      ACHIEVEMENTS,
      TESTIMONIALS,
      PREVIEW_CARD,
    } = formInputs;

    switch (type) {
      case NAME:
        return setName(value);
      case COURSE_NAME:
        return setCourseName(value);
      case IMAGE_URL:
        return setImageUrl(value);
      case ACHIEVEMENTS:
        return setAchievements(value);
      case TESTIMONIALS:
        return setTestimonial(value);
      case PREVIEW_CARD:
        return setCardUrl(value);
    }
  };

  let entries = {
    name,
    courseName,
    imageUrl,
    achievements,
    testimonial,
    cardUrl,
  };

  let templateData = {
    heading: `${t('studentIndex.tempData.heading','Dear ')} ${name}!`,
    subHeading: t('studentIndex.tempData.subHeading','Please fill this small form and let us know how you feel about learning from us. Your stories will inspire us and all our other students to get better and excel.'),
    mainButton: {
      text: t('studentIndex.tempData.mainBtnText','Proceed'),
      image: null,
      bgColor: '#009AE0',
      handleClick: () => {
        changePage(1);
      },
    },
    auxButton: null,
  };

  if (err) {
    return <Error />;
  } else if (!pageIndex) {
    return (

      <StudentHome
        testimonial={testimonial}
        entries={entries}
        templateData={templateData}
        showSuccesModal={showSuccesModal}
        loading={loading}
      />
     
    );
  } else {
    return (
      <Form
        stepLoading={stepLoading}
        entries={entries}
        setEntries={setterFunction}
        changePage={changePage}
        pageIndex={pageIndex}
      />
    );
  }
}

export default TestimonialForm;
