import React from 'react';
import Combined from '../../resources/images/combinedcup.png'

import { Trans, useTranslation } from "react-i18next";

const TutorCard = () => {
    const {t} = useTranslation()
    return (
        <div className='TutorCard'>
            <div className='TutorCard__Top'>
                <div className='TutorCard__Top__Left'>
                    {t("tutorCard.text1","See what your students have to say about learning from you.")}</div>
                <div>
                    <img src={Combined} alt=''/>
                </div>
            </div>
            <div className='TutorCard__Bottom'>
            {t("tutorCard.text2","Let the world know what your champion students have to say about you. Choose what testimonials you want to share on your mobile application.")}
            </div>
        </div>
    )
}

export default TutorCard
