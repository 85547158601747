import React, { useEffect, useState } from 'react';
import './style.scss';

import { GET } from '../../utils/constants';
import {
  directDeepLink,
  getQuery,
  makeApiCal,
  parseToken,
} from '../../utils/functions';
import Flickity from 'react-flickity-component';
import TestimonialCard from './TestimonialCard';
import Loader from '../UI/Loader';
import { useTranslation } from 'react-i18next';

function Published() {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, catchError] = useState(false);
  const {t} = useTranslation()
  const [addDeeplink, setAddDeeplink] = useState(false);

  useEffect(() => {
    let action = {
      method: GET,
      route: 'v2/course/studentTestimonial?isPublished=1&limit=10&offset=0',
      storeData: (e) => {
        setList(
          e?.studentTestimonial?.studentTestimonial
            ?.userPublishedStudentTestimonial
        );
        setAddDeeplink(e?.studentTestimonial?.addTestimonialDeeplink);
      },
      catchError: catchError,
    };

    makeApiCal(action);
  }, []);

  useEffect(() => {
    if (list?.length) {
      setLoading(false);
      console.log('CODERAN');
    } else {
      setLoading(true);
    }
  }, [list]);

  // useEffect(() => {
  //   const token =
  //     getQuery()['token'] || localStorage.getItem('TestimonialToken');

  //   let userType = parseToken(token)?.type;

  //   if (userType == 1) {
  //     setShowAdd(true);
  //   } else {
  //     setShowAdd(false);
  //   }
  // }, []);

  const flickityOptions = {
    initialIndex: 1,
    wrapAround: true,
    autoPlay: 3000,
    pageDots: true,
    prevNextButtons: true,
    arrowShape: {
      x0: 10,
      x1: 60,
      y1: 50,
      x2: 70,
      y2: 40,
      x3: 30,
    },
    selectedAttraction: 0.2,
    friction: 0.8,
  };

  return (
    <div className="Published">
      <div className="Published__Top">
        <div className="Published__Top--Heading">
          {t("Published.top.heading","What our students have to say")}
        </div>
        {addDeeplink ? (
          <div
            className="Published__Top--Add"
            onClick={directDeepLink(addDeeplink, 'SOURCE_HOME')}
          >
            + {t("Published.top.add","Add")}
          </div>
        ) : null}
      </div>
      {loading ? (
        <Loader />
      ) : (
        <div className="Published__Body">
          <Flickity
            className={'Published__List'} // default ''
            elementType={'div'} // default 'div'
            options={flickityOptions} // takes flickity options {}
            disableImagesLoaded={false} // default false
            reloadOnUpdate // default false
            static // default false
          >
            {list?.map((item, i) => (
              <TestimonialCard key={i} {...item} />
            ))}
          </Flickity>
        </div>
      )}
    </div>
  );
}

export default Published;
