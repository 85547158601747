import React from 'react';
import Tick from '../../resources/images/successbluetick.svg';
import Close from '../../resources/images/x.svg';

import { useTranslation } from 'react-i18next';

const BottomUpCardSuccess = ({ setShowModal }) => {
  const { t } = useTranslation()
  return (
    <div className='SuccessModal'>
      <div className='close'>
        <img
          src={Close}
          alt='c'
          onClick={() => {
            setShowModal(false);
          }}
        />
      </div>
      <img src={Tick} alt='a' className='SuccessModal__img' />
      <div className='SuccessModal__Bold'>{t("success.text2", "Success")}!</div>
      <div className='SuccessModal__Description'>
        {t("success.text3", "You have successfully published your student testimonials. They will appear on the home screen at the student end.")}
      </div>
    </div>
  );
};

export default BottomUpCardSuccess;
