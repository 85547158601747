import React from 'react';

function ProgressBar(props) {
  const { barColor, fillPercent } = props;
  return (
    <div className="ProgressBar">
      <div
        className="ProgressBar__Bar"
        style={{ width: `${fillPercent}%`, backgroundColor: barColor }}
      />
    </div>
  );
}

export default ProgressBar;
