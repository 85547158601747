import React from 'react';
import { Trans, useTranslation } from "react-i18next"

function Error() {
  const {t} = useTranslation()
  return (
    <div className="Error">
      <div className="Error__Heading">{t("error.somethingWentWrong1","Something went wrong")}</div>
      <div className="Error__Text">
      {t("error.anErrorOccured1","An error occured, please come back later")}...
      </div>
    </div>
  );
}

export default Error;
