import React from 'react';
import Close from '../../resources/images/x.svg';
import LandingTemplate from './LandingTemplate';
const BottomUpShareModal = ({ setShowModal,data,landingTemplateId }) => {
  return (
    <div>
      <div className='close'>
        <img
          src={Close}
          alt='c'
          onClick={() => {
            setShowModal(false);
          }}
        />
      </div>
      <LandingTemplate data={data} id={landingTemplateId}/>
    </div>
  );
};

export default BottomUpShareModal;
