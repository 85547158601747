import React, { useState, useEffect } from 'react';
import { formInputs } from '../../../utils/constants';

import { Trans, useTranslation } from "react-i18next"

function Achievement(props) {
  const { value, setValue, changePage, skip } = props;
  const { t } = useTranslation();
  const [disable, updateDisable] = useState(true);
  const [verifed, Verify] = useState(false);

  let maxLength = 20;

  useEffect(() => {
    if (value && value.length > 1) {
      updateDisable(false);
    } else {
      updateDisable(true);
    }
  }, [value]);

  const validateEntry = () => {
    if (value && value.length > 1) {
      Verify(false);
    } else {
      Verify(true);
    }
  };

  return (
    <div className="Form__Entry">
      <div className="Skip" onClick={() => skip(formInputs.ACHIEVEMENTS)}>
        {t("form.achievement.skip1","Skip")}
      </div>
      <div className="Form__Entry--Label">
        {t("form.achievement.yourAchievements1","What were your achievements?")}
        <span>*{t("form.achievement.optional1","Optional")}</span>
      </div>
      <div className="Form__Entry--Info">
        {t("form.achievement.rankInExam1","You can write your Rank or marks you attained in the exam")}
      </div>
      <div className="Form__Entry__Body">
        <div className="Form__Entry__Body__InputArea">
          <input
            className="Form__Entry__Body__InputArea--Input"
            type="text"
            placeholder={t("form.achievement.enterYourAchievement","Enter your achievement")}
            maxLength={maxLength}
            value={value}
            onChange={(e) =>
              setValue(
                e.target.value.slice(0, maxLength),
                formInputs.ACHIEVEMENTS
              )
            }
            onBlur={validateEntry}
          />
          <span>{value?.length ? maxLength - value.length : maxLength}</span>
        </div>

        {verifed && (
          <div className="Form__Entry__Body--Info">
            *{t("form.achievement.validCourseName1","Please enter a valid course/program name")}
          </div>
        )}
      </div>
      <button
        className="Form__Entry--Button"
        disabled={disable}
        onClick={() => changePage(1)}
      >
        {t("form.achievement.next1","Next")}
      </button>
    </div>
  );
}

export default Achievement;
