import React, { useState } from 'react';
import InputField from './InputField';
import './searchbox.scss';
import Search from '../../resources/images/search.png';

import { Trans, useTranslation } from 'react-i18next'


const SearchBox = ({
  handleBlur,
  handleChange,
  maxLength,
  placeHolder,
  value,
  name,
  showCount,
  inputFieldclassName,
  handleFocus,
}) => {

  const { t } = useTranslation();

  const [focus, setFocus] = useState(false);
  const handleFocuss = () => {
    setFocus(true);
  };
  const handleBlurr = (e) => {
    setFocus(false);
  };
  return (
    <div className='SearchBox__Wrapper'>
      <div className={focus ? 'SearchBox focus' : 'SearchBox'}>
        <div className='SearchBox__Search'>
          <img src={Search} alt='s' />
        </div>
        <InputField
          handleBlur={(e) => handleBlurr(e)}
          handleChange={handleChange}
          value={value}
          name={name}
          placeHolder={t('searchBox.placeholder.searchByName','Search by Name')}
          inputFieldclassName={inputFieldclassName}
          handleFocus={handleFocuss}
        />
      </div>
    </div>
  );
};

export default SearchBox;
