export const getFont = () => {
    var url = new URL(window.location.href);
  var defaultFont =
  url.searchParams.get("defaultLanguage");
    const lang = defaultFont
    let font = "Noto Sans";

    if (lang === "VI" || lang ==="vi") {
        font = "Be Vietnam Pro";
    }

    return font;
};

