import React, { useState, useEffect, useRef } from 'react';
import { formInputs } from '../../../utils/constants';

import uploadToS3 from '../../../utils/uploadToS3';

import upload from '../../../resources/images/upload.svg';
import Cancel from '../../../resources/images/Cancel.svg';

import { Trans, useTranslation } from "react-i18next"

function Image(props) {
  const { value, setValue, changePage, skip, setImage } = props;
  const { t } = useTranslation();
  const fileInput = useRef(null);
  const [disable, updateDisable] = useState(true);
  const [uploadedImage, uploadImage] = useState();
  const [ImageUrl, setImageUrl] = useState();

  useEffect(async () => {

    if (uploadedImage) {
      setImageUrl(await uploadToS3([uploadedImage]));
    }
  }, [uploadedImage]);

  useEffect(() => {
    if (ImageUrl) {
      setValue(ImageUrl[0]?.url, formInputs.IMAGE_URL);
    }
  }, [ImageUrl]);

  useEffect(() => {
    if (value) {
      updateDisable(false);
    } else {
      updateDisable(true);
    }
  }, [value]);

  return (
    <div className="Form__Entry">
      <div className="Skip" onClick={() => skip(formInputs.IMAGE_URL)}>
        {t("form.image.skip1","Skip")}
      </div>
      <div className="Form__Entry--Label">
        {t("form.image.pleaseUpload1","Please upload a camera facing photograph of yours")}
      </div>
      <div className="Form__Entry__Body">
        {value ? (
          <div className="Form__Entry__Body__UploadedImage">
            <img
              className="Form__Entry__Body__UploadedImage--Image"
              src={value}
              alt="Your Image"
            />
            <img
              className="Form__Entry__Body__UploadedImage--Remove"
              src={Cancel}
              alt="X"
              onClick={() => setValue(null, formInputs.IMAGE_URL)}
            />
          </div>
        ) : (
          <div
            className="Form__Entry__Body__ImageArea"
            onClick={() => fileInput.current.click()}
          >
            <input
              className="Form__Entry__Body__ImageArea--Input"
              type="file"
              accept="Image/*"
              style={{ display: 'none' }}
              ref={fileInput}
              onChange={(e) => {
                uploadImage(e.target.files[0])
                setImage(e.target.files[0])}
              }
            
            />
            <img src={upload} alt="^" />{t("form.image.uploadPhoto1","Upload Photo")}
          </div>
        )}
      </div>
      <button
        className="Form__Entry--Button"
        disabled={disable}
        onClick={() => changePage(1)}
      >
        {t("form.coursename.next1","Next")}
      </button>
    </div>
  );
}

export default Image;
