import { apiBaseUrl, GET, POST } from './constants';
import axios from 'axios';

export const instance = axios.create();
instance.interceptors.response.use(
  function (res) {
    return res.data;
  },
  function (error) {
    let message = 'Something went wrong';
    let status = 'failure';
    if (error.response && error.response.status === 401) {
      console.error('Error', 'Session Timed Out. Please Login Again.');
      // import store and logout action and then call logout actions
      throw {};
    }
    try {
      message = error.response.data.message || message;
      status = error.response.data.status || status;
    } catch (e) {}
    throw { message, status };
  }
);

export const parseToken = (token) => {
  if (token !== 'undefined') {
    return JSON.parse(
      token?.replace(/^[^]+\.([^]+)\.[^]+$/, (token, payload) =>
        Buffer.from(payload, 'base64').toString('utf8')
      )
    );
  }
};

export const getQuery = () => {
  let b = window.location.search
    .slice(1)
    .split('&')
    .map((qStr) => qStr.split('='))
    .reduce((acc, inc) => {
      acc[inc[0]] = inc[1];
      return acc;
    }, {});
  return b;
};

const token = getQuery()['token'] || localStorage.getItem('TestimonialToken');

if (token) {
  localStorage.setItem('TestimonialToken', token);
}
// const token =
//   'eyJhbGciOiJIUzM4NCIsInR5cCI6IkpXVCJ9.eyJpZCI6NDU4MDM3Nywib3JnSWQiOjQyMTI0LCJuYW1lIjoiTWFudGVzaCIsImVtYWlsIjpudWxsLCJtb2JpbGUiOiI5MTcwMzg3MTE0ODIiLCJ0eXBlIjozLCJpYXQiOjE2MzAzMDM3MjEsImV4cCI6MTYzMjAzMTcyMX0.X_6gUIbAwKq_7C-tKz-dUg3JwS0Y8L3aQ5y6yjk9lda05OBFJazk8Sa3fP-NUbrX';
// localStorage.setItem('testimonialToken', token);

export const makeApiCal = (action) => {
  // PARAMETERS - method, route, storeData, catchError, body

  switch (action.method) {
    case GET:
      return fetch(`${apiBaseUrl}/${action.route}`, {
        method: GET,
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
          Connection: 'keep-alive',
        },
      })
        .then((res) => res.json())
        .then((res) => {
          res?.status === 'success'
            ? action.storeData(res?.data)
            : action.catchError(res.message);
        })
        .catch((err) => {
          action.catchError(err);
        });
    case POST:
      return fetch(`${apiBaseUrl}/${action.route}`, {
        method: POST,
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
          Connection: 'keep-alive',
        },
        body: JSON.stringify(action.body),
      })
        .then((res) => res.json())
        .then((res) => {
          res?.status === 'success'
            ? action.storeData(res?.data)
            : action.catchError(res.message);
        })
        .catch((err) => action.catchError(err));
  }
};
export const directDeepLink = (url, source) => {
  const action = {
    method: 'POST',
    route: 'v2/storeDeeplink',
    body: { deeplink: url },
    storeData: (e) => console.log(e),
    catchError: (e) => console.error(e),
  };

  switch (source) {
    case 'SOURCE_HOME':
      return () => {
        window?.webkit?.messageHandlers?.onDeeplinkExecutedV2?.postMessage(
          JSON.stringify(url)
        );
        window?.mobile?.exceuteDeeplink(JSON.stringify(url));
        makeApiCal(action);
      };
    case 'SOURCE_WEBVIEW':
      return () => {
        window?.webkit?.messageHandlers?.onDeeplinkExecutedV2?.postMessage(
          JSON.stringify(url)
        );
        window?.mobile?.onDeeplinkExecutedV2(JSON.stringify(url));
        // alert(JSON.stringify(url));

        makeApiCal(action);
      };
  }
};

export const hitDeeplinkExecutedV2 = (
  androidDeeplinkObj,
  iosDeeplinkObj,
  android = true,
  iOS = true
) => {
  console.log("deeplink called", androidDeeplinkObj);
  try {
    android && window?.mobile?.onDeeplinkExecutedV2(androidDeeplinkObj);
    iOS &&
      window?.webkit?.messageHandlers?.onDeeplinkExecutedV2?.postMessage(
        iosDeeplinkObj
      );
  } catch (err) {
    console.log("Something went wrong...");
  }
};