import { React, useState, useEffect } from "react";
import "./finalTestimonial.css";

import html2canvas from "html2canvas";
import "b64-to-blob";
import uploadToS3 from "../../../utils/uploadToS3";

import award from "../../../resources/images/BadgeThumb.svg";
import { formInputs } from "../../../utils/constants";

import { Trans, useTranslation } from "react-i18next"

const FinalTestimonial = (props) => {
	const { image, value, setValue, changePage } = props;
	let { testimonial, achievements, courseName, name, stepLoading } =
		props?.value;
		const { t } = useTranslation();
	const [disable, updateDisable] = useState(true);
	const [localImage, setLocalImage] = useState();
	const [finalImageUrl, setfinalImageUrl] = useState(); // finalImageUrl contains the url of s3 uploadded image in the promise result
	const [randomNumber, setRandomNumber] = useState(
		Math.floor(Math.random() * 3)
	);

	useEffect(() => {
		if (finalImageUrl) {
			updateDisable(false);
		} else {
			updateDisable(true);
		}
	}, [finalImageUrl]);

	const readUrl = (image) => {
		if (image) {
			var reader = new FileReader();
			reader.onload = function (e) {
				// document
				//   .getElementById('fi-image')
				//   .setAttribute('src', e.target.result);
				setLocalImage(e.target.result);
			};

			reader.readAsDataURL(image);
		}
	};

	useEffect(() => {
		if (image) {
			readUrl(image);
		}
	}, [image]);

	useEffect(() => {
		if (image) {
			if (localImage) {
				download();
			}
		} else {
			download();
		}
	}, [localImage]);

	useEffect(() => {
		if (finalImageUrl) {
			setValue(finalImageUrl[0]?.url, formInputs.PREVIEW_CARD);
		}
	}, [finalImageUrl]);

	const download = async () => {
		function dataURItoBlob(dataURI) {
			var binary = atob(dataURI.split(",")[1]);
			var array = [];
			for (var i = 0; i < binary.length; i++) {
				array.push(binary.charCodeAt(i));
			}
			return new Blob([new Uint8Array(array)], { type: "image/jpeg" });
		}
		var container = document.getElementById("final-image");
		document.body.scrollTop = 0;
		document.documentElement.scrollTop = 0;

		html2canvas(container, { allowTaint: true }).then(async function (canvas) {
			var link = document.createElement("a");
			document.body.appendChild(link);
			link.download = "Poster.png";
			link.href = canvas.toDataURL("image/png");
			var blobData = dataURItoBlob(link.href);

			blobData.name = "abc.png";
			setfinalImageUrl(await uploadToS3([blobData]), formInputs.PREVIEW_CARD);
		});
	};

	const colorSet = ["#82D9FF", "#D9EAA6", "#FFD5D5"];

	return (
		<div className="Form__Entry">
			<div className="Form__Entry--Label">{t("form.finalTestimonial.previewOfTestimonial1","A preview of your testimonial")}</div>
			<div className="PreviewCard" id="final-image">
				<div
					className="PreviewCard__Content"
					style={{
						backgroundColor: colorSet[randomNumber],
					}}
				>
					<div className="PreviewCard__Content__Top">{testimonial}</div>
					<div className="PreviewCard__Content__Bottom">
						<div className="PreviewCard__Content__Bottom__Left">
							<div className="PreviewCard__Content__Bottom__Left--Name">
								{name}
							</div>

							{courseName ? (
								<div className="PreviewCard__Content__Bottom__Left--CourseName">
									{courseName}
								</div>
							) : (
								""
							)}

							{achievements ? (
								<div className="PreviewCard__Content__Bottom__Left--Achievement">
									<img src={award} alt="Badge" />
									<div className="PreviewCard__Content__Bottom__Left--Achievement--Text">
										{achievements}
									</div>
								</div>
							) : (
								""
							)}
						</div>

						{image && localImage ? (
							<div className="PreviewCard__Content__Bottom__Right">
								<img src={localImage} id="fi-image" />
							</div>
						) : (
							""
						)}
					</div>
				</div>
			</div>
			<button
				disabled={disable}
				className="Form__Entry--Button"
				onClick={() => changePage(1)}
			>
				{stepLoading ? t("form.finalTestimonial.loading","Loading...") : t("form.finalTestimonial.proceed","Proceed")}
			</button>
		</div>
	);
};

export default FinalTestimonial;
