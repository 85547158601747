import React from 'react';
import Warning from '../../resources/images/info.svg';
import Close from '../../resources/images/x.svg';

import { Trans, useTranslation } from "react-i18next";

const WarningCard = ({
  setShowModal,
 unpublishCards
}) => {
  const {t} = useTranslation()
  const handleClick = () => {
   unpublishCards()
    setShowModal(false);
  };
  return (
    <div className='WarningCard'>
      <div className='close'>
        <img
          src={Close}
          alt='c'
          onClick={() => {
            setShowModal(false);
          }}
        />
      </div>

      <div className='MaxLimit'>
        <div className='MaxLimit__Logo'>
         
            <img src={Warning} alt='w' />
         
        </div>
        <div className='MaxLimit--Bold'>
        {t("warningCard.unpublished2","Unpublish Confirmation")}
        </div>
        <div className='MaxLimit__Description'>
        {t("warningCard.aboutToTurnOff","You are about to turn off your student testimonials. Your students will not be able to see them any further. Are you sure you want to proceed ")}?
        </div>
        <div className='MaxLimit__okay' onClick={() => handleClick()}>
        {t("warningCard.turnOffButton","TURN-OFF")}
        </div>
      </div>
    </div>
  );
};
export default WarningCard;
