import React from 'react';
import Modal from '../../UI/Modal';

import Done from '../../../resources/images/WhiteTick.svg';

import { Trans, useTranslation } from "react-i18next"

function Success(props) {

  const { t } = useTranslation();

  return (
    <Modal propClass="Success">
      <div className="Success__Done">
        <img src={Done} alt="Tick" />
      </div>
      <div className="Success__Content">
        <div className="Success__Content--Heading">{t("SuccessModal.thankyou2","Thank you")}!</div>
        <div className="Success__Content--Text">
          {t("SuccessModal.successfulltSubmitted2","You have successfully submitted your testimonial")}
        </div>
      </div>
    </Modal>
  );
}

export default Success;
