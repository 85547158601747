import axios from 'axios';
import i18n from '../i18n';
import { getQuery } from './functions';
// import { toastr } from "react-redux-toastr";
const instance = axios.create();
instance?.interceptors?.response?.use(
  function (res) {
    return res.data;
  },
  function (error) {
    let message = i18n.t('somethingWrong', 'Something went wrong');
    let status = 'failure';
    if (error.response && error.response.status === 401) {
      // toastr.error("Error", "Session Timed Out. Please Login Again.");
      // import store and logout action and then call logout actions
      throw {};
    }
    try {
      message = error.response.data.message || message;
      status = error.response.data.status || status;
    } catch (e) {}
    throw { message, status };
  }
);
// instance?.interceptors?.request?.use(function (config) {
//   const defaultLanguage = getQuery()['defaultLanguage'];
//   if (defaultLanguage) {
//     config.headers['accept-language'] =
//       defaultLanguage?.substring(0, 2)?.toLowerCase() || 'en';
//   }
// });

export default instance;
