import React from 'react';
import TestimonialCard from '../../UI/TestimonialCard';
import './cardContainer.scss';
import InfiniteScroll from 'react-infinite-scroll-component';
import TutorCard from '../../UI/TutorCard';
import Loader from '../../UI/Loader';

import { Trans, useTranslation } from "react-i18next"; 

const CardContainer = ({
  data,
  checkedArray,
  unCheckedArray,
  setCheckedArray,
  setUnCheckedArray,
  handleCheckBoxClick,
  fetchMoreData,
  isMoreData,
  isLoader,
}) => {
  const {t} = useTranslation()


  return (
    <div className="TestimonialCards">
      <InfiniteScroll
        dataLength={data?.length}
        next={() => {
          fetchMoreData();
        }}
        hasMore={isMoreData()}
        loader={<></>}
        // className={search || SELECT_ALL_COURSE_VALUES ? `large` : `small`}
        height={`calc(100vh - 200px)`}
      >
        <>
          <TutorCard />
          <div className="TestimonialCards__Container">
            <div className="TestimonialCards__Heading">
              {t("cardContainer.testimonials1","Testimonials")} ({data?.length})
            </div>
            {data.map((testimonial) => (
              <TestimonialCard
                data={testimonial}
                checkedArray={checkedArray}
                unCheckedArray={unCheckedArray}
                setCheckedArray={setCheckedArray}
                setunCheckedArray={setUnCheckedArray}
                handleCheckBoxClick={handleCheckBoxClick}
                isTutor
              />
            ))}
            {isLoader && <Loader />}
            {data.length===0&&<div className='NothingToShow'>{t("cardContainer.nothignToShowww","Nothing to show")}</div>}
          </div>
        </>
      </InfiniteScroll>
    </div>
  );
};

export default CardContainer;
