import React, { useEffect, useState } from 'react';
import './style.scss';
import {
  getQuery
} from '../../utils/functions';
import Flickity from 'react-flickity-component';
import BannerCarouselCard from './BannerCarouselCard';
import Loader from '../UI/Loader';
import { useTranslation } from 'react-i18next';
import bannerDataGraphQl from "./bannerDataGraphQl";

function BannerCarousel() {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const {t} = useTranslation()

  async function fetchData() {
    const url = 'https://api.classplusapp.com/cams/graph-api';
    const token = getQuery()['token'] || localStorage.getItem('TestimonialToken');

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            query: bannerDataGraphQl,
            variables: { token }
        })
    };
    try {
        const response = await fetch(url, options);
        const data = await response.json();
        setList(data?.data?.images);
    } catch (error) {
        console.error('Error fetching data:', error);
    }
  }

  useEffect(()=>{
    fetchData();
  },[])

  useEffect(() => {
    if (list?.length) {
      setLoading(false);
      console.log('CODERAN');
    } else {
      setLoading(true);
    }
  }, [list]);

  const flickityOptions = {
    initialIndex: 0,
    wrapAround: true,
    autoPlay: 3000,
    pageDots: true,
    prevNextButtons: false,
    arrowShape: {
      x0: 10,
      x1: 60,
      y1: 50,
      x2: 70,
      y2: 40,
      x3: 30,
    },
    selectedAttraction: 0.2,
    friction: 0.8,
  };

  var url = new URL(window.location.href);
  let title = url.searchParams.get("title");

  return (
    <div className="Banner">
      <div className="Banner__Top">
        <div className="Banner__Top--Heading">
          {title ? title : ''}
        </div>
      </div>
      {loading ? (
        <Loader />
      ) : (
        <div className="Banner__Body" id="bannerDiv">
          <Flickity
            className={'Banner__List'} // default ''
            elementType={'div'} // default 'div'
            options={flickityOptions} // takes flickity options {}
            disableImagesLoaded={false} // default false
            reloadOnUpdate // default false
            static // default false
            prevNextButtons={false}
          >
            {list?.map((item, i) => (
              <BannerCarouselCard key={i} {...item} />
            ))}
          </Flickity>
        </div>
      )}
    </div>
  );
}

export default BannerCarousel;
