import React from 'react';

import Badge from '../../resources/images/Badge.svg';
import Quotes from '../../resources/images/Quotes.svg';
import Profile from '../../resources/images/Profile.png';



function TestimonialCard(props) {
  const { data, isTutor } = props;

  return (
    <div className="TestimonialCard">
      <div className="TestimonialCard__Top">
        <div className="TestimonialCard__Top__Left">
          <img src={data?.imageUrl || Profile} alt="i" />
        </div>

        <div className="TestimonialCard__Top__Right">
          <div className="TestimonialCard__Top__Right--Name">{data?.name}</div>
          {data?.courseName ? (
            <div className="TestimonialCard__Top__Right--Course">
              {data?.courseName}
            </div>
          ) : (
            ''
          )}
          {data?.achievements ? (
            <div className="TestimonialCard__Top__Right--Achievement">
              <img src={Badge} alt="Badge" /> {data?.achievements}
            </div>
          ) : (
            ''
          )}
        </div>
        {isTutor && (
          <div className="TestimonialCard__Checkbox">
            <input
              type="checkbox"
              name={data?.id}
              onClick={(e) => {
                props.handleCheckBoxClick(e);
              }}
              checked={props?.checkedArray?.includes(data?.id)}
              className="TestimonialCard__Checkbox"
            />
          </div>
        )}
      </div>
      <div className="TestimonialCard__Bottom">
        <img src={Quotes} alt='"' />
        <span className="TestimonialCard__Bottom--Testimonial">
          {data?.testimonial}
        </span>
      </div>
    </div>
  );
}

export default TestimonialCard;
